<template>
    <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" id="Layer_1" x="0" y="0" version="1.1" viewBox="0 0 100 100">
        <path
            d="M50.2 48.9c1.7 1.7 4.5 1.7 6.2 0l2.3-2.3c.9-.9 2.5-.9 3.4 0 .9.9.9 2.5 0 3.4l-2.3 2.3c-.9.9-2.5.9-3.4 0-.4-.4-1-.4-1.4 0s-.4 1 0 1.4c.9.9 2 1.3 3.1 1.3s2.3-.4 3.1-1.3l.4-.4 1.4 1.4c.2.2.5.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4L63 51.9l.4-.4c1.7-1.7 1.7-4.5 0-6.2-1.7-1.7-4.5-1.7-6.2 0l-2.3 2.3c-.9.9-2.5.9-3.4 0-.9-.9-.9-2.5 0-3.4l2.3-2.3c.9-.9 2.5-.9 3.4 0 .4.4 1 .4 1.4 0s.4-1 0-1.4c-1.7-1.7-4.5-1.7-6.2 0l-.4.4-1.4-1.4c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l1.4 1.4-.4.4c-1.7 1.7-1.7 4.5 0 6.2zM35.2 31.2c0 1.3-1.1 2.4-2.4 2.4-.6 0-1 .4-1 1s.4 1 1 1c2.4 0 4.4-2 4.4-4.4v-.6h2c.6 0 1-.4 1-1s-.4-1-1-1h-2V28c0-2.4-2-4.4-4.4-4.4s-4.4 2-4.4 4.4v3.2c0 1.3-1.1 2.4-2.4 2.4s-2.4-1.1-2.4-2.4V28c0-1.3 1.1-2.4 2.4-2.4.6 0 1-.4 1-1s-.4-1-1-1c-2.4 0-4.4 2-4.4 4.4v.6h-2c-.6 0-1 .4-1 1s.4 1 1 1h2v.6c0 2.4 2 4.4 4.4 4.4s4.4-2 4.4-4.4V28c0-1.3 1.1-2.4 2.4-2.4 1.3 0 2.4 1.1 2.4 2.4v3.2z"
            class="st0" />
        <path
            d="M82.5 49.4h-1c-.6 0-1 .4-1 1s.4 1 1 1h1c1.7 0 3.1 1.4 3.1 3.1V78c0 1.7-1.4 3.1-3.1 3.1H37.4L34.2 78l15-.1c.3 0 .5-.1.7-.3l32.2-32.2c1-1 1.5-2.2 1.5-3.6s-.5-2.6-1.5-3.6L65.5 21.6c-1-1-2.2-1.5-3.6-1.5s-2.6.5-3.6 1.5L37 42.9v-.6c0-2.4-2-4.4-4.4-4.4s-4.4 2-4.4 4.4v3.2c0 1.3-1.1 2.4-2.4 2.4s-2.4-1.1-2.4-2.4v-3.2c0-1.3 1.1-2.4 2.4-2.4.6 0 1-.4 1-1s-.4-1-1-1c-2.4 0-4.4 2-4.4 4.4v.7h-2c-.6 0-1 .4-1 1s.4 1 1 1h2v.6c0 2.4 2 4.4 4.4 4.4s4.4-2 4.4-4.4v-3.2c0-1.3 1.1-2.4 2.4-2.4s2.4 1 2.4 2.3v2.5l-9 9c-.2.2-.3.4-.3.7V77c0 .2.1.5.2.6-3.1 1.4-6 1.9-8 1.5-.9-.2-1.5-.6-1.8-1.1-.8-1.3 0-3.7 2.1-6.3l3.1 3.1c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4L14.3 65V20c0-1.7 1.4-3.1 3.1-3.1H41c1.7 0 3.1 1.4 3.1 3.1v10.4c0 .6.4 1 1 1s1-.4 1-1V20c0-2.8-2.3-5.1-5.1-5.1H17.5c-2.8 0-5.1 2.3-5.1 5.1v45.6c0 .3.1.5.3.7l4.1 4.1c-2.8 3.4-3.7 6.6-2.3 8.8.6 1 1.7 1.7 3.1 2 .6.1 1.2.2 1.9.2 2.9 0 6.6-1.2 10.2-3.3h1.8l4.8 4.8c.2.2.4.3.7.3h45.5c2.8 0 5.1-2.3 5.1-5.1V54.5c0-2.8-2.3-5.1-5.1-5.1zM27.7 76V55l32-32c.6-.6 1.4-.9 2.2-.9.8 0 1.6.3 2.2.9l16.6 16.6c.6.6.9 1.3.9 2.2 0 .8-.3 1.6-.9 2.2L48.8 75.9l-16.1.1c2.4-1.8 4.3-3.8 5.6-5.8 1.8-2.7 2.1-5.2 1-7-.5-.8-1.3-1.4-2.3-1.7 1-.4 2.1-.4 3.1.1 1.8.9 2.6 3.2 1.7 5-.2.3-.3.6-.5.8-.4.4-.3 1 .1 1.4.4.4 1 .3 1.4-.1.3-.4.6-.8.8-1.2 1.4-2.8.3-6.3-2.6-7.7-2.8-1.4-6.3-.3-7.7 2.6-1.4 2.8-.3 6.3 2.6 7.7h.1c-1.5 2-3.7 3.9-6.2 5.5-.2.1-.4.3-.6.4h-1.5zm7.4-12.7.1-.1c.8.1 1.9.4 2.5 1.2.6.9.4 2.4-.6 4.1-.1 0-.2-.1-.3-.1-1.9-1.1-2.6-3.3-1.7-5.1z"
            class="st0" />
        <path
            d="M80.2 69.1H63.8c-.6 0-1 .4-1 1s.4 1 1 1h16.3c.6 0 1-.4 1-1s-.4-1-.9-1zM80.2 74.9h-13c-.6 0-1 .4-1 1s.4 1 1 1h13c.6 0 1-.4 1-1s-.5-1-1-1z"
            class="st0" />
    </svg>
</template>
<style>
.st0 {
    @apply fill-current;
}
</style>
